import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicModule } from '@app-public/module';
import { DashboardModule } from './admin/dashboard.module';
import { PageErrorModule } from './page-error/page-error.module';

const appRoutes: Routes = [
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule,
    DashboardModule,
    PublicModule,
    PageErrorModule
  ]
})
export class AppRoutingModule {}
